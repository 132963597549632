
// VARIABLES
var getCurrentDate = new Date();
var currentYear = getCurrentDate.getFullYear().toString();

// COMMENT : footer component
export default function Footer() {
    return (

      // Footer section
      <footer>
        <p>Copyright &copy; {currentYear} MarcX Meinhardt</p>
      </footer>
    );
}