
const socialLinks = [
    {
        platform: 'YouTube',
        url: 'https://www.youtube.com/@marcxvlogs',
        username: '@marcxvlogs'
    },
    {
        platform: 'Instagram',
        url: 'https://instagram.com/marcxmeinhardt',
        username: '@marcxmeinhardt'
    },
    {
        platform: 'Tiktok',
        url: 'https://www.tiktok.com/@marcxmeinhardt',
        username: '@marcxmeinhardt'
    },
    {
        platform: 'GitHub',
        url: 'https://github.com/marcmeinhardt',
        username: '@marcmeinhardt'
    },
    {
        platform: 'Discord',
        url: 'https://discord.com/invite/UmjpV7483y',
        server: 'MotivationX Friends'
    },
    {
        platform: 'X',
        url: 'https://twitter.com/marcxmeinhardt',
        username: '@marcxmeinhardt'
    },
];

export default socialLinks;
