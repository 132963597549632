
const projects = [
    {
        type: 'youtube channel',
        name: 'MarcX vlogs',
        image_url: 'url',
        description: '',
    },
    {
        type: 'game',
        name: 'Tople',
        image_url: 'url',
        description: '',
    },
    {
        type: 'app',
        name: 'Mood Bored',
        image_url: 'url',
        description: '',
    },
    {
        type: 'social_platform',
        name: 'CryptoMap',
        image_url: 'url',
        description: '',
    },
    {
        type: 'community',
        name: 'MotivationX Friends',
        image_url: 'url',
        description: '',
    },
    {
        type: 'freelance business',
        name: 'Represent Studios',
        image_url: 'url',
        description: '',
    },
]

export default projects;
