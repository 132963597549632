import React, { useEffect } from 'react';

// COMMENT : Main Navigation Bar component
export default function NavBar() {

    useEffect(() => {
        const toggleButton = document.getElementById('theme-toggle');
        const currentTheme = localStorage.getItem('theme') || 'light';
        
        document.documentElement.setAttribute('data-theme', currentTheme);

        const handleToggle = () => {
            const currentTheme = document.documentElement.getAttribute('data-theme');
            const newTheme = currentTheme === 'light' ? 'dark' : 'light';

            document.documentElement.setAttribute('data-theme', newTheme);
            localStorage.setItem('theme', newTheme);
        };

        if (toggleButton) {
            toggleButton.addEventListener('click', handleToggle);
        } else {
            console.error('error : Toggle button not found.');
        }

        return () => {
            if (toggleButton) {
                toggleButton.removeEventListener('click', handleToggle);
            }
        };
    }, []); 

    return (
        < header className = "app-navbar">
            <nav>
                <button id='theme-toggle' type='button' aria-label='toggle theme'>toggle dark mode</button>
            </nav>
        </header >
    );
};
