
// components
import Body from '../src/interface/body.js';
import Footer from '../src/interface/footer.js';
import NavBar from '../src/interface/navbar.js';

// styles
import '../src/css/App.css';
import '../src/css/root.css';
import '../src/css/navbar.css';
import '../src/css/body.css';
import '../src/css/footer.css';


function App() {
  return (
    <div className="App">
      <NavBar />
      <Body />
      <Footer />
    </div >
  );
}

export default App;
