import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.js';

// VARIABLES : 
const apiKey = process.env.REACT_APP_YOUTUBE_API_KEY;
const channelId = process.env.REACT_APP_CHANNEL_ID;

// example usage
console.log('YouTube API Key : ', apiKey);
console.log('YouTube Channel ID : ', channelId);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

