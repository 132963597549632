import React, { useState, useEffect } from 'react';
import socialLinks from '../data/social_links.js';
import projects from '../data/projects.js';
// import YoutubeSubscriberCount from '../apis/YoutubeSubscriberCount.js';

import '../css/body.css';
import '../css/App.css';
import '../css/root.css';

// COMMENT : dynamically update time according to user time regiom
function Greeting() {
    const [greeting, setGreeting] = useState('');

    useEffect(() => {
        const getGreeting = () => {
            const now = new Date();
            const hour = now.getHours();

            if (hour < 3) {
                return 'good evening';
            } else if (hour < 12) {
                return 'good morning';
            } else if (hour < 16) {
                return 'hello';
            } else if (hour < 18) {
                return 'good afternoon';
            } else {
                return 'good evening';
            }
        };

        setGreeting(getGreeting());
    }, []);

    return (
        <span>
            {greeting}
        </span>
    );
};


// COMMENT : function to calculate age in years as a float given a birthdate
function calculateAge(birthdate) {
    const now = new Date();
    const birthDate = new Date(birthdate);

    // calculate the difference in total milliseconds
    const diffTime = now - birthDate;

    // convert milliseconds to years considering leap years (365.25 days per year)
    const diffYears = diffTime / (1000 * 60 * 60 * 24 * 365.25);

    return diffYears.toFixed(8); // Nine decimal places for higher precision
};

// COMMENT : age component
function AgeComponent() {
    const [age, setAge] = useState(calculateAge('1998-03-02'));

    useEffect(() => {
        const birthdate = '1998-03-02';

        // function to update the age
        const updateAge = () => {
            setAge(calculateAge(birthdate));
        };

        // set an interval to update the age every second
        const intervalId = setInterval(updateAge, 500);

        // clean up the interval on component unmount
        return () => clearInterval(intervalId);
    }, []);

    return (
        <span id="currentAge">
            {age}
        </span>
    );
};

// COMMENT : about section component
function AboutMeSection() {
    return (
        <section id="about-container" className='about-container'>
            <div className='profile-header-container'>
                <img src="https://yt3.googleusercontent.com/4KE8bW_em0zomsxyf4LkxBFoC40GqjF-5pDbszWbjzy9sW4JZ8pf4q1rPSthbfGlQJWeONia=s176-c-k-c0x00ffffff-no-rj" alt="Profile" className="profile-picture" />
                <h1>MarcX Meinhardt</h1>
            </div>
            <div className='space-1em'></div>
            <strong>@marcxmeinhardt</strong>
            <p>
                <Greeting />,<br />
                i like building and optimising stuff. <br />
                and i am <AgeComponent /> years old.
            </p>
        </section>
    );
};

// COMMENT : social links section component
function SocialLinksSection() {
    const socialLinksItems = socialLinks?.map(link =>
        <li key={link?.id}><a href={link?.url} target="_blank" rel="noopener noreferrer" className='link'>{link?.platform}</a></li>
    );

    return (
        <section id="social-links-section">
            <ul className='social-links-container'>{socialLinksItems}</ul>
        </section>
    );
};

// COMMENT : statistics section component
function StatsSection() {

    return (
        <section id="stats-section">
            <ul className='stats-container'>
                <li>### Discord members</li>
                {/* <li><YoutubeSubscriberCount /></li>  */}
                <li>### TikTok followers</li>
                <li>### Instagram followers</li>
                <li>### X followers</li>
            </ul>
        </section>
    );
};

// COMMENT : projetcs section component
function ProjectsSection() {
    const projectItems = projects?.map(project =>
        <div key={project?.id} className='project-item'>
            {project.url} {project.name}
        </div>
    );

    return (
        <section id="projects-section">
            <div className='projects-container'>
                {projectItems}
            </div>
        </section>
    );
};


// COMMENT : body component
export default function Body() {
    return (
        <div className='main-container'>
            <AboutMeSection />
            <div className='space-1em'></div>

            <SocialLinksSection />
            <div className='space-1em'></div>
            
            <StatsSection />
            <div className='space-1em'></div>
            
            <ProjectsSection />
            <div className='space-1em'></div>
        </div>
    );
}

